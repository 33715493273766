import React, { useState } from "react";
import { Layout } from "antd";
import "./index.scss";
import { Footer } from "antd/lib/layout/layout";
import { LayoutProvider, useLayoutSelector } from "./layoutContext";
import { FilterProvider } from "../filters/filterContext";
import { LoadingOverlayComponent } from "../components/loading-overlay";
import { SideNavBarComponent } from "./side-nav";
import { HeaderComponent } from "./header";
import { useTranslation } from "react-i18next";
import { useCustomLayoutDispatch } from "../hooks";

const { Sider, Content } = Layout;

const LayoutFullPage: React.FC = ({ children }) => {
	const { t } = useTranslation();
	const showPageLoading = useLayoutSelector((s: any) => s.showPageLoading);
	const layoutDispatch = useCustomLayoutDispatch();
	let [collapsedToggle, setCollapsedToggle] = useState<boolean>(false);

	const changeMenuToggle = (value: any) => {
		setCollapsedToggle(value);
	}

	const onScroll = (event: any) => {
		if (event.target.scrollTop > 0) {
			layoutDispatch({ scrollHeight: true });
		} else {
			layoutDispatch({ scrollHeight: false });
		}
	};

	return (
		<FilterProvider>
			<div className="container-xxl px-0">
				{/* <Layout style={{ height: "calc(100vh)" }}> */}
				<Layout style={{ height: "100%" }}>
					<HeaderComponent hideMenu={collapsedToggle} menuToggle={(e)=> changeMenuToggle(e)}/>
					<Layout>
						<Sider className="sider-box" trigger={null} collapsible collapsedWidth={0} collapsed={!collapsedToggle}>
							<SideNavBarComponent collapsed={(e)=> changeMenuToggle(e)} />
						</Sider>

						<Content className="content-style" onScroll={onScroll}>
							{children}
							<Footer>{t("common.footer")}</Footer>
							{showPageLoading && <LoadingOverlayComponent></LoadingOverlayComponent>}
						</Content>
					</Layout>
				</Layout>
			</div>
		</FilterProvider>
	);
};

const FullPageComponent: React.FC = (props: any) => (
	<LayoutProvider>
		<LayoutFullPage {...props} />
	</LayoutProvider>
);

export default FullPageComponent;

