import Keycloak from "keycloak-js";

const _kc: any = new (Keycloak as any)({
  "url": process.env.REACT_APP_AUTH_SERVER_URL ? process.env.REACT_APP_AUTH_SERVER_URL : 'http://localhost:5050/auth/',
  "realm": process.env.REACT_APP_AUTH_REALM ? process.env.REACT_APP_AUTH_REALM : 'CleverTap',
  "clientId": process.env.REACT_APP_AUTH_RESOURCE ? process.env.REACT_APP_AUTH_RESOURCE : 'b360-local',
});
let userNotAuthenticated = false;
/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
 const initKeycloak = (onAuthenticatedCallback: any) => {
  _kc.init({
	onLoad: 'login-required',
	pkceMethod: 'S256',
	checkLoginIframe: false,
	scope: "roles email profile"
  })
  .then((authenticated: any) => {
	if (authenticated) {
	  // console.log(_kc)
	  // console.log(hasRole(['Authenticated-User']));
	  // get roles from the keycloak
	  const rolesAdded = _kc.resourceAccess;
	  // console.log(rolesAdded);
    userNotAuthenticated = rolesAdded && rolesAdded !=undefined ? !checkUserHasRole(rolesAdded) : true;
    onAuthenticatedCallback();  
	} else {
	  doLogin();
	}
  })
};



const getStatus = () => userNotAuthenticated;

const doLogin = _kc.login;

const doLogout = _kc.logout;

const getToken = () => _kc.idToken;

const isLoggedIn = () => !!_kc.token;

const getAccessToken = () => _kc.token;

 const updateToken = (successCallback: any) =>
  _kc.updateToken(60)
	 .then(successCallback)
	 .catch(doLogin);

const getUsername = () => _kc.idTokenParsed?.preferred_username;

const getName = () => _kc.idTokenParsed?.name;

const getEmail = () => _kc.idTokenParsed?.email;

const getUserId = () => _kc.idTokenParsed?.sub;

const hasRole = (roles: any) => roles.some((role: any) => _kc.hasRealmRole(role));

const checkUserHasRole = (roles: any) => {
  // console.log(roles);
  // console.log(process.env.REACT_APP_AUTH_KEYCLOAK_RESOURCE_ACCESS_KEY)
  // console.log(process.env.REACT_APP_AUTH_KEYCLOAK_RESOURCE_ACCESS_KEY_ROLES)
  const resourceAccessKey = process.env.REACT_APP_AUTH_KEYCLOAK_RESOURCE_ACCESS_KEY;
  if (resourceAccessKey && roles[resourceAccessKey]) {
	const allComingRoles = roles[resourceAccessKey].roles;
	const isKeyPresent = allComingRoles.includes(process.env.REACT_APP_AUTH_KEYCLOAK_RESOURCE_ACCESS_KEY_ROLES);
	// console.log(isKeyPresent)
	return isKeyPresent;
  }
   return false;
  }

const UserService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  updateToken,
  getUsername,
  getUserId,
  hasRole,
  getAccessToken,
  getName,
  getEmail,
  getStatus
};

export default UserService;
