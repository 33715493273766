import React, { Suspense, useRef } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import IdleTimer from "react-idle-timer";
import { Modal } from "antd";
import "antd/dist/antd.css";
import "./App.scss";
import LayoutFullPage from "./layout-full-page";
import { LoadingOverlayComponent } from "./components/loading-overlay";
import UserService from "./services/UserService";
import "./intersceptor/http-interceptors";

const MarketingComponent = React.lazy(() => import("./marketing/tactical"));
const Fileuploadcomponent = React.lazy(() => import("./file-upload"));
const SalesComponent = React.lazy(() => import("./gtm_dashboard"));
const OperationalComponent = React.lazy(() => import("./marketing/operational"));

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			staleTime: Infinity,
			refetchOnWindowFocus: false,
			refetchOnMount: true,
			refetchOnReconnect: "always",
			retry: false,
			cacheTime: 1000 * 60 * 15,
		},
	},
});

const handleOnIdle = (event: any) => {
	sessionStorage.clear();
	UserService.doLogout();
};

const handleOk = () => {
	sessionStorage.clear();
	UserService.doLogout();
};

const App: React.FC = () => {
	const idleTimer = useRef(null);
	const tokenTimer = setInterval(()=>{
		UserService.updateToken( ()=>{});
	},1000 * 60 * 4.5)
	return (
		<div>
			<IdleTimer ref={() => idleTimer} timeout={1000 * 60 * 60 * 24} onIdle={handleOnIdle} />
			{UserService.getStatus() ? (
				<Modal title="Authentication Error" visible={true} onOk={handleOk} cancelButtonProps={{ style: { display: "none" } }}>
					<p>
						You, {UserService.getName() ? UserService.getName() : UserService.getUsername() ? UserService.getUsername() : UserService.getEmail() ? UserService.getEmail() : "NA"}, do not have permission to
						access the application.
					</p>
				</Modal>
			) : (
				<QueryClientProvider client={queryClient}>
					<Suspense fallback={<LoadingOverlayComponent />}>
						<BrowserRouter basename={process.env.PUBLIC_URL}>
							<LayoutFullPage.fullPage>
								<Switch>
									<Route exact path="/">
										<SalesComponent />
									</Route>
									<Route exact path="/marketing/tactical">
										<MarketingComponent />
									</Route>
									<Route exact path="/marketing/operational">
										<OperationalComponent />
									</Route>
									<Route exact path="/uploadfile">
										<Fileuploadcomponent />
									</Route>
								</Switch>
							</LayoutFullPage.fullPage>
						</BrowserRouter>
					</Suspense>
				</QueryClientProvider>
			)}
		</div>
	);
};

export default App;
