export const API_END_POINTS = {
    dashboard_tactical: "dashboard/tactical/requires-attention",
    get_attention_summary_details: "dashboard/tactical/attention-summary",
    opportunities_focus: "dashboard/tactical/opportunities-focus",
    quarterly_trend: "dashboard/tactical/quarterly-trends",
    segment: "dashboard/common/segment",
    region: "/dashboard/common/region",
    pipeline_status: "/dashboard/tactical/pipeline-status/all",
    trend_cards: "/dashboard/tactical/trends-card",
    pipeline_status_details: "/dashboard/tactical/pipeline-status/",
    opportunity_analysis: "/dashboard/tactical/opportunity-analysis",
    channel_details: "/dashboard/operational/channel-details",
    funnel_details: "/dashboard/operational/funnel-details",
    get_updated_time: "/dashboard/common/updated-time",
    like_dislike_message: "/dashboard/tactical/requires-attention-log",
    opportunity_deal_medium: "/dashboard/operational/opportunity-deal-medium",
    outreach_activity: "/dashboard/operational/outreach-activity",
    segment_details_table: "/dashboard/operational/segment-details",
    regional_view: "/dashboard/strategic/regional-view",
    downloadXls: "dashboard/operational/downloadxls",
    funnel_details_table: "dashboard/operational/funnel-details-table",
    insights : "dashboard/operational/insights",
    hubspot_table : "dashboard/operational/hubspot-activity",
    insights_outreach_stage: 'dashboard/operational/outreach-stage',
    sales_opportunities_to_focus: 'dashboard/sales/opportunities-focus',
    opportunities_owned: 'dashboard/sales/opportunities-owned',
    sales_attention_summary: 'dashboard/sales/attention-summary',
    deal_medium_dropdown: 'dashboard/common/teams',
    opportunity_type: 'dashboard/common/opportunity-type',
    opportunities_owned: 'dashboard/sales/opportunities-owned',
    sales_attention_summary: 'dashboard/sales/attention-summary',
    deal_medium_dropdown: 'dashboard/common/teams',
    opportunity_type: 'dashboard/common/opportunity-type',
    sales_opportunity_stages: 'dashboard/sales/stages-display',
    sales_opportunity_focus_table: 'dashboard/sales/opportunities-focus/table',
    additional_analysis: 'dashboard/sales/additional-analysis',
    additional_analysis_stages: 'dashboard/sales/additional-analysis-stages',
    business_source: 'dashboard/sales/business-sources',
    regional_performance: 'dashboard/sales/regional-performance',
    analysis_booking: 'dashboard/sales/analysis/booking',
    contacts_created: 'dashboard/sales/contracts',
    leads_created: 'dashboard/sales/analysis/lead',
    mql_tab: 'dashboard/sales/analysis/mql',
    sql_tab: 'dashboard/sales/analysis/sql',
    contracts_table_view: '/dashboard/sales/contracts-table-view',
    opportunities_tab: '/dashboard/sales/opportunity-analysis',
    contracts_list: 'dashboard/sales/contracts-details',
    teams_table: 'dashboard/sales/team-data',
    contribution_deal_medium : 'dashboard/sales/contribution-deal-medium',
    teams_stages: 'dashboard/sales/team-stage',
    teams_side_table : 'dashboard/sales/team-data-email',
    conversion : "dashboard/sales/conversion-stage",
    file_upload: "dashboard/aws/s3/uploaded/files",
 }