import React from "react";
import { useHistory } from "react-router-dom";
import { Menu } from "antd";
import "./index.scss";
import { ExportOutlined } from "@ant-design/icons";
import marketing from "../assets/images/marcketing.svg";
import sales from "../assets/images/salse.svg";
import SubMenu from "antd/lib/menu/SubMenu";
import { useTranslation } from "react-i18next";

interface SideNavBarProps {
	collapsed: (arg0: boolean) => void;
}

export const SideNavBarComponent: React.FC<SideNavBarProps> = (props) => {
	const { t } = useTranslation();
	const history = useHistory();

	const handleMenuClick = (e: any) => {
		history.push(e.key);
		props.collapsed(false);
	};

	return (
		<Menu style={{ width: "200px" }} theme="light" mode="inline" onClick={handleMenuClick} defaultSelectedKeys={[window.location.pathname]}>
			<Menu.Item key="/" icon={<img src={sales} alt="icon" />}>
				{t("layout_full_page.salse")}
			</Menu.Item>
			<SubMenu className="d-none d-sm-block d-md-block d-lg-block d-xl-none" style={{ color: "#6A6F80" }} key="sub1" icon={<img src={marketing} alt="icon" className="pr-2" />} title="Marketing">
				<Menu.Item key="/marketing/tactical">{t("layout_full_page.tactical")}</Menu.Item>
				<Menu.Item key="/marketing/operational">{t("layout_full_page.operational")}</Menu.Item>
			</SubMenu>
			<Menu.Item className="col-auto d-none d-sm-none d-md-none d-lg-none d-xl-block" key="/marketing/tactical" icon={<img src={marketing} alt="icon" />}>
				{t("layout_full_page.marketing")}
			</Menu.Item>
			<Menu.Item key="/uploadfile" icon={<ExportOutlined style={{ fontSize: "1.2rem" }} />}>
				{t("layout_full_page.configuration")}
			</Menu.Item>
		</Menu>
	);
};
